<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        AI Other Services
      </h2>

      <img
        src="../../assets/img/sales_people.jpg"
        alt="AI Other Services Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
      <div class="flex flex-col items-center">
        <div class="w-full">
          <div class="banner bg-cover bg-fixed bg-center py-4">
            <div class="my-2 w-full" v-for="(svc, i) in predictiveServices" :key="i">
                  <div class="hover:elevation-5 mx-2 hover:bg-white">
                    <h3 class="font-medium px-4 pt-3">{{ svc.title }}</h3>
                    <p class="px-4 pb-3">{{ svc.text }}</p>
                  </div>
                </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {
  name: 'People_Analytics',
  data() {
    return {
      predictiveServices: Object.freeze([
        {
          title: 'HR Reporting & Workforce Diagnosis',
          text:
            'How can HR leverage on existing talent data in the company? An effective talent metrics reporting strategy can help to provide timely, accurate and meaningful information for HR organisations and business functions',
        },
        {
          title: 'Training Workshops',
          text:
            'Predictive Analytics Training Workshop – predictive analytics applications from descriptive, predictive to prescriptive analytics. Power BI for HR Training Workshop – unlock analytical capabilities through Power BI',
        },
      ]),
    };
  },
  components: {},
};
</script>
